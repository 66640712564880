import React from "react"

export const TitleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="182" height="10" viewBox="0 0 182 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.8366 10C30.0739 9.07169 2.52198 10.1081 0.519339 9.82831C-1.11563 9.45317 1.49719 2.84689 3.01481 1.87407C3.54676 1.52436 4.16476 1.47351 5.58069 1.63247C7.82583 1.93131 36.8562 1.33997 36.8562 1.33997C37.2396 1.54344 38.3895 0.856744 38.6007 1.10472C39.2657 1.68332 86.3198 0.894891 90.3329 0.8631C92.7658 0.844025 90.8414 1.23824 93.7045 1.07928C99.8063 0.761364 113.105 1.06657 117.728 0.704142C121.429 0.405302 122.226 0.392594 125.778 0.63421C129.408 0.850392 148.707 0.538832 150.913 0.074676C151.452 -0.0524901 151.867 -0.00797814 151.867 0.138263C151.867 0.170054 165.471 0.278142 165.533 0.392591C165.635 0.538832 166.05 0.608769 166.433 0.507036C167.145 0.354437 181.625 -0.198737 181.852 0.227269C182.564 1.44806 180.569 6.64915 178.887 7.91446C177.166 9.26878 167.716 7.74915 160.12 8.56302L124.753 8.64568L117.173 9.15434C116.305 8.71561 112.964 9.82196 110.946 9.03353C110.093 8.72197 104.07 8.67745 102.74 9.00809C101.582 9.26242 70.7681 8.62023 67.6077 9.50403C61.9753 8.76647 39.1327 9.82197 33.8366 10Z" fill="#FFA700" />
    </svg>

  </span>
)