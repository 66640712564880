import React from "react"
import Slider from "react-slick"

const Conversacion = ({ data, title }) => {
  return (
    <section className="container-convesacion-whatsapp-precio ">
      {title}
      <section className="cards-imagen-pages-relative">
        {data.map((ele, i ) => {
          return (
            <img
            key={i}
            src={ele}
            alt=""
            className="cards-imagen-pages-relative-img"
          />
          )
        })}
      </section>
      <section className="image-help-customermobile">
            <Slider
              dots={false}
              infinite={true}
              slidesToShow={1}
              slidesToScroll={1}
              autoplay={true}
              speed={500}
              autoplaySpeed={18000}
              pauseOnFocus={false}
              draggable={true}
              pauseOnHover={false}
              initialSlide={0}
              arrows={true}
            >
              {data.map((ele, i ) => {
          return (
            <img
            key={i}
            src={ele}
            alt=""
            className="cards-imagen-pages-relative-img"
          />
          )
        })}
            </Slider>
          </section>
    </section>
  )
}

export default Conversacion
