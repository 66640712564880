import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="248" height="10" viewBox="0 0 248 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.1071 10C40.9798 9.07169 3.43654 10.1081 0.70767 9.82831C-1.52019 9.45317 2.04013 2.84689 4.1081 1.87407C4.83295 1.52436 5.67506 1.47351 7.60446 1.63247C10.6638 1.93131 50.2217 1.33997 50.2217 1.33997C50.744 1.54344 52.311 0.856744 52.5988 1.10472C53.5049 1.68332 117.623 0.894891 123.091 0.8631C126.406 0.844025 123.784 1.23824 127.685 1.07928C136 0.761364 154.121 1.06657 160.421 0.704142C165.463 0.405302 166.55 0.392594 171.39 0.63421C176.336 0.850392 202.633 0.538832 205.639 0.074676C206.375 -0.0524901 206.94 -0.00797814 206.94 0.138263C206.94 0.170054 225.477 0.278142 225.562 0.392591C225.701 0.538832 226.266 0.608769 226.788 0.507036C227.758 0.354437 247.489 -0.198737 247.798 0.227269C248.768 1.44806 246.05 6.64915 243.758 7.91446C241.413 9.26878 228.536 7.74915 218.186 8.56302L169.993 8.64568L159.664 9.15433C158.481 8.71561 153.929 9.82196 151.179 9.03353C150.017 8.72197 141.809 8.67745 139.997 9.00809C138.42 9.26242 96.4312 8.62023 92.1247 9.50403C84.4498 8.76647 53.3236 9.82197 46.1071 10Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="213" height="10" viewBox="0 0 213 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39.6 10C35.1963 9.07169 2.95154 10.1081 0.607797 9.82831C-1.30565 9.45317 1.7522 2.84689 3.52832 1.87407C4.15088 1.52436 4.87415 1.47351 6.53125 1.63247C9.1588 1.93131 43.134 1.33997 43.134 1.33997C43.5826 1.54344 44.9284 0.856744 45.1756 1.10472C45.9538 1.68332 101.023 0.894891 105.719 0.8631C108.567 0.844025 106.314 1.23824 109.665 1.07928C116.806 0.761364 132.37 1.06657 137.781 0.704142C142.111 0.405302 143.045 0.392594 147.202 0.63421C151.45 0.850392 174.036 0.538832 176.618 0.074676C177.249 -0.0524901 177.735 -0.00797814 177.735 0.138263C177.735 0.170054 193.655 0.278142 193.729 0.392591C193.848 0.538832 194.333 0.608769 194.782 0.507036C195.615 0.354437 212.561 -0.198737 212.827 0.227269C213.66 1.44806 211.325 6.64915 209.357 7.91446C207.343 9.26878 196.283 7.74915 187.393 8.56302L146.002 8.64568L137.131 9.15433C136.115 8.71561 132.205 9.82196 129.843 9.03353C128.845 8.72197 121.796 8.67745 120.24 9.00809C118.885 9.26242 82.822 8.62023 79.1233 9.50403C72.5315 8.76647 45.7981 9.82197 39.6 10Z" fill="#FFBA00" />
    </svg>
  </span>
)