import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
       width="275" height="12" viewBox="0 0 275 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M51.1538 10.1453C44.5744 9.38835 4.15865 11.3937 1.27398 11.2454C-1.28578 10.9864 0.499094 5.11217 2.48539 4.19127C3.18963 3.86396 4.10952 3.7774 6.30915 3.86779C8.23833 3.94621 21.5293 3.48939 53.6834 2.47532C54.314 2.64474 55.9027 2.00741 56.2807 2.22073C56.5689 2.34444 61.2393 2.29712 68.1819 2.18966C75.9721 2.06801 97.179 1.65184 107.638 1.48799C123.074 1.23867 117.268 1.28255 134.94 1.07348C138.636 1.02881 135.75 1.39978 140.096 1.24539C149.366 0.921609 169.633 1.19499 176.648 0.903481C182.275 0.669204 183.501 0.656149 188.91 0.890245C193.237 1.07641 223.765 1.09042 227.21 0.743465C228.04 0.63988 228.671 0.692033 228.653 0.820402C229.013 0.832658 249.334 1.23463 249.424 1.34099C249.568 1.46985 250.181 1.54428 250.776 1.46232C251.858 1.32041 273.948 1.26986 274.253 1.65047C275.115 2.74748 271.369 7.23662 268.715 8.30626C265.935 9.42577 252.235 7.83773 240.477 8.37734C240.477 8.37734 237.159 8.33941 232.183 8.27413C183.588 7.6807 185.246 7.99281 175.977 8.32218C174.661 7.92735 169.7 8.87277 166.618 8.199C165.339 7.92154 156.305 7.89426 154.285 8.16734C153.852 8.22746 150.642 8.2401 145.972 8.24275C141.319 8.24546 135.207 8.23258 129.022 8.26974C125.92 8.2883 122.8 8.30122 119.843 8.33137C116.868 8.35589 114.037 8.40876 111.53 8.46261C106.499 8.57026 102.748 8.70969 101.593 8.91839C92.4164 8.34348 59.9184 9.78087 51.1538 10.1453Z" fill="#FFBA00"/>
      </svg>
      
  </span>
)

export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="116" height="12" viewBox="0 0 116 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.5087 10.0557C18.7439 9.31035 1.74868 11.3864 0.536153 11.2432C-0.539576 10.9887 0.221105 5.11133 1.0578 4.18696C1.35445 3.85842 1.74135 3.77024 2.66597 3.85679C3.4769 3.93183 9.06554 3.45175 22.5856 2.38142C22.8505 2.54973 23.5195 1.90963 23.6781 2.12228C23.799 2.24549 25.7626 2.19 28.6817 2.07039C31.9571 1.9351 40.8737 1.48183 45.2712 1.29968C51.7613 1.02334 49.3201 1.07739 56.7502 0.837389C58.3044 0.786245 57.0903 1.16227 58.9179 1.00028C62.8155 0.660273 71.3358 0.898181 74.2857 0.594402C76.6517 0.350279 77.1673 0.335078 79.4409 0.559707C81.2598 0.738294 94.0945 0.698884 95.5435 0.345902C95.8926 0.240866 96.1577 0.291914 96.1498 0.420315C96.3013 0.43194 104.844 0.798344 104.882 0.904548C104.942 1.03315 105.199 1.10652 105.45 1.02352C105.905 0.879715 115.192 0.790502 115.32 1.17058C115.68 2.26607 114.098 6.76175 112.98 7.83603C111.809 8.9604 106.052 7.39633 101.108 7.95652C101.108 7.95652 99.7129 7.92439 97.6207 7.86782C77.1915 7.35944 77.888 7.66865 73.9904 8.01424C73.4382 7.62171 71.3505 8.5758 70.0562 7.90744C69.5188 7.63221 65.7207 7.62074 64.8708 7.89736C64.6887 7.95823 63.3392 7.9765 61.3757 7.98732C59.4198 7.99816 56.8499 7.99599 54.2494 8.04397C52.9454 8.06795 51.6339 8.08633 50.3905 8.12166C49.1395 8.15139 47.9491 8.20921 46.8952 8.26745C44.7798 8.3839 43.2025 8.5299 42.7167 8.74061C38.8596 8.18176 25.1942 9.67602 21.5087 10.0557Z" fill="#FFBA00"/>
      </svg>      
  </span>
)